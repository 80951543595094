///////////////////// TEST ANT MEDIA PUBLISHING PAGE /////////////////////

import React, { useState, useEffect, useRef } from "react";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

import axiosCall from "../../lib/axios";
import config from "../../config";

const Streamer = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const [publishing, setPublishing] = useState(false);
  const [dataChannel, setDataChannel] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const msgBox = useRef();
  const msgRef = useRef();
  const nameRef = useRef();
  const webRTCAdaptor = useRef(null);
  var publishedStreamId = useRef(null);
  var username = useRef(null);
  var avatar = useRef(null);

  useEffect(() => {
    webRTCAdaptor.current = new WebRTCAdaptor({
      websocket_url:
        "wss://" + config.server.live.domain + "WebRTCAppEE/websocket",
      mediaConstraints: {
        video: true,
        audio: true,
      },
      peerconnection_config: {
        iceServers: [{ urls: config.server.live.ice }],
      },
      sdp_constraints: {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false,
      },
      localVideoId: "localVideo",
      // bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
      dataChannelEnabled: true, // enable or disable data channel
      callback: (info, obj) => {
        switch (info) {
          case "pong":
            break;
          case "initialized":
            console.log(info);
            setWebsocketConnected(true);
            break;
          case "publish_started":
            console.log(info);
            console.log(obj);
            setPublishing(true);
            break;
          case "data_channel_opened":
            console.log(info);
            setDataChannel(true);
            break;
          case "data_received":
            console.log(info);
            console.log(obj);
            try {
              let msg = JSON.parse(obj.data);
              if (msg.chat) popMessage(msg.chat);
              if (Number.isInteger(msg.count)) setActiveCount(msg.count);
            } catch (e) {}
            break;
          case "data_channel_closed":
            console.log(info);
            setDataChannel(false);
            break;
          case "publish_finished":
            console.log(info);
            setPublishing(false);
            break;
          default:
            console.log(info);
            console.log(obj);
            break;
        }
      },
      callbackError: function (error, message) {
        console.log(error, message);
        handleStopPublishing();
      },
    });
  }, []);

  ////////////// FUNCTIONS //////////////////
  const initPublish = async () => {
    //init
    let data = { type: "publish" };
    if (nameRef.current.value.trim())
      data.streamName = nameRef.current.value.trim();

    // retrieve token and stream id
    let result = await axiosCall("live/totp", data);
    console.log(result);
    if (result.success) {
      handlePublish(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      initPublish();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const handlePublish = (obj) => {
    console.log(obj);
    if (
      obj.streamId &&
      obj.subscriberId &&
      obj.subscriberCode &&
      obj.username &&
      obj.avatar
    ) {
      publishedStreamId.current = obj.streamId;
      username.current = obj.username;
      avatar.current = obj.avatar;
      webRTCAdaptor.current.publish(
        obj.streamId,
        "",
        obj.subscriberId,
        obj.subscriberCode,
        obj.streamName || ""
      );
    }
  };

  const handleStopPublishing = () => {
    webRTCAdaptor.current.stop(publishedStreamId.current);
    publishedStreamId.current = null;
    username.current = null;
    avatar.current = null;
  };

  const sendMessage = () => {
    let msg = msgRef.current.value.trim();
    if (msg) postMessage(msg);
  };

  const postMessage = (message) => {
    if (publishedStreamId.current && username.current && avatar.current) {
      let messageObj = {
        chat: {
          message,
          username: username.current,
          avatar: avatar.current,
        },
      };
      webRTCAdaptor.current.sendData(
        publishedStreamId.current,
        JSON.stringify(messageObj)
      );
      popMessage({ message, self: true });
    }
  };

  const popMessage = (message) => {
    let HTML;
    if (message.count) {
      setActiveCount(message.count);
    } else if (message.self) {
      HTML = `<div style='align-self:flex-end; text-align:right'><i>${message.message}</i> &nbsp; - &nbsp; <b>me</b></div>`;
    } else {
      HTML = `<div style='display:flex; gap: 12px'><div><div class='avatar med' style='background-image: url(${
        config.content.server + message.avatar
      })'></div><div><b>${message.username}</b></div></div><div><i>${
        message.message
      }</i></div></div>`;
    }
    msgBox.current.innerHTML = HTML + msgBox.current.innerHTML;
    msgRef.current.value = "";
  };

  ////////////// RENDER GUI //////////////////

  return (
    <main className="main-page">
      <div className="page-section">
        <h2>Live Stream Publisher</h2>
      </div>
      {publishing && (
        <div className="page-section sub">
          VIEWERS: <b>{activeCount}</b>
        </div>
      )}
      <div className="page-section sub" style={{ maxWidth: "480px" }}>
        <video
          id="localVideo"
          controls={true}
          autoPlay={true}
          muted={true}
          width="480px"
          height="360px"
        ></video>
      </div>
      <div className="page-section sub">
        <input
          type="text"
          ref={nameRef}
          placeholder="enter a stream name (optional)"
        ></input>
        <div style={{ marginTop: "0.25em" }}>
          {publishing ? (
            <button className="button" onClick={handleStopPublishing}>
              STOP STREAM
            </button>
          ) : (
            <button
              className="button"
              disabled={!websocketConnected}
              onClick={initPublish}
            >
              PUBLISH STREAM
            </button>
          )}
        </div>
        {publishing && (
          <div style={{ marginTop: "0.25em" }}>
            STREAM ID {publishedStreamId.current}
          </div>
        )}
        {dataChannel && (
          <>
            <div style={{ marginTop: "2em" }}>LIVE CHAT</div>

            <div style={{ marginTop: "0.25em", display: "flex", gap: "0.5em" }}>
              <input
                type="text"
                ref={msgRef}
                placeholder="enter a message"
              ></input>
              <button className="button" onClick={() => sendMessage()}>
                Send
              </button>
            </div>

            <div
              ref={msgBox}
              style={{
                marginTop: "0.5em",
                display: "flex",
                maxWidth: "480px",
                height: "200px",
                border: "1px solid black",
                padding: "0.5em",
                flexDirection: "column-reverse",
                gap: "0.5em",
                overflow: "scroll",
              }}
            ></div>
          </>
        )}
      </div>
    </main>
  );
};

export default Streamer;
