import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import config from "../../config";

const LiveStreams = ({ language, main_focus, signout }) => {
  //////////// INITIALIZATION ///////////
  const context = useSelector((state) => state.context.value);
  const navigate = useNavigate();

  //////////// RENDER GUI ///////////
  if (context.free_streams) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                context.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + context.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.navigation.live_streams}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {context.title}
              </div>
            </div>
          </div>
        </div>

        <div className="page-section" style={{ maxWidth: "66rem" }}>
          <div style={{ marginTop: "0.5em" }} className="quote-text">
            Live Streaming is currently in <b>beta</b> and only available in the
            Community Hub to community experts and instructors. It will be
            become available to all community members in release 2.0 of the
            Yongo App.
          </div>

          {/* help generate topics */}
          <button
            style={{
              alignSelf: "flex-start",
              marginTop: "0.5em",
              fontWeight: "500",
            }}
            className="button"
            onClick={() => {
              navigate("/pathwaystream");
            }}
          >
            {language.labels.live.start}
          </button>
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};

export default LiveStreams;
