import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axiosCall from "../../lib/axios";
import config from "../../config";

const PathwayStream = ({ language, main_focus, signout }) => {
  //////////// INITIALIZATION ///////////
  const context = useSelector((state) => state.context.value);
  const navigate = useNavigate();

  const [pathways, setPathways] = useState([]);
  const [gotPathways, setGotPathways] = useState(false);
  const [pathwayTitle, setPathwayTitle] = useState(null);
  const [pathwayDescription, setPathwayDescription] = useState(null);

  const [pathwayBites, setPathwayBites] = useState({});
  const [bites, setBites] = useState([]);

  console.log(pathways);

  useEffect(() => {
    if (!gotPathways) getPathways();
  }, []);

  const getPathways = async () => {
    let result = await axiosCall("community/pathways", { bite_count: true });
    if (result.success) {
      setGotPathways(true);
      setPathways(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// BITE SELECTION ///////////
  const pickPathway = (str) => {
    let obj = JSON.parse(str);

    setPathwayTitle(obj.pathway);
    if (obj.description) {
      setPathwayDescription(obj.description);
    } else {
      setPathwayDescription(null);
    }

    if (pathwayBites[obj._id]) {
      setBites(pathwayBites[obj._id]);
    } else {
      getPathwayBites(obj._id);
    }
  };

  const getPathwayBites = async (pid) => {
    alert();
    let result = await axiosCall("pathway/bites", { pid });
    if (result.success) {
      let _pathwayBites = { ...pathwayBites };
      _pathwayBites[pid] = result.data;
      setPathwayBites(_pathwayBites);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathwayBites();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////
  const mapped_pathways = pathways.map((pathway, index) => {
    return (
      <option value={JSON.stringify(pathway)} key={index}>
        {pathway.pathway}
      </option>
    );
  });

  if (context.free_streams) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div className="page-section" style={{ display: "flex" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <div
              role="img"
              aria-label={language.labels.aria.logo.replace(
                /{org}/g,
                context.title
              )}
              className="menu-crest"
              style={{
                height: "3em",
                width: "3em",
                backgroundImage: `url("${
                  config.content.server + context.crest
                }")`,
              }}
            ></div>
            <div style={{ width: "0.5em" }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 2.5em)",
              }}
            >
              <h1 className="heading" style={{ width: "100%", padding: "0" }}>
                {language.labels.live.new}
              </h1>
              <div className="handle2" style={{ width: "100%" }}>
                {context.title}
              </div>
            </div>
          </div>
        </div>

        {/* pathway selector */}
        {pathways.length > 0 ? (
          <div
            className="page-section"
            style={{ marginTop: "0.5em", maxWidth: "66rem" }}
          >
            <div className="text-label">
              {language.labels.live.select_pathway}
            </div>
            <select
              className="large"
              aria-label={language.labels.live.select_pathway}
              onChange={(e) => {
                pickPathway(e.target.value);
              }}
            >
              <option value="" selected disabled hidden></option>
              {mapped_pathways}
            </select>
          </div>
        ) : (
          <div
            className="page-section"
            style={{
              marginTop: "0.5em",
              maxWidth: "66rem",
              display: "flex",
              gap: "1em",
            }}
          >
            <div className="glyphs alert" style={{ fontSize: "2em" }}>
              !
            </div>
            <div>{language.labels.live.no_pathways}</div>
          </div>
        )}

        {/* select bites */}
        {(pathwayTitle || bites.length > 0) && (
          <div className="page-section">
            <div className="block-title">{pathwayTitle}</div>
            {pathwayDescription && (
              <div className="quote-text" style={{ marginTop: "0.5em" }}>
                {pathwayDescription}
              </div>
            )}
          </div>
        )}
      </main>
    );
  } else {
    return <></>;
  }
};

export default PathwayStream;
