///////////////////// TEST ANT MEDIA PUBLISHING PAGE /////////////////////

import React, { useState, useEffect, useRef } from "react";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

import axiosCall from "../../lib/axios";
import config from "../../config";

const LivePlay = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////

  const [playing, setPlaying] = useState(false);
  const [dataChannel, setDataChannel] = useState(false);
  const [websocketConnected, setWebsocketConnected] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const [streamName, setStreamName] = useState(null);
  const [error, setError] = useState(null);

  const msgBox = useRef();
  const msgRef = useRef();
  const streamRef = useRef(null);
  const webRTCAdaptor = useRef(null);
  var playingStreamId = useRef(null);
  var username = useRef(null);
  var avatar = useRef(null);

  useEffect(() => {
    if (webRTCAdaptor.current === undefined || webRTCAdaptor.current === null) {
      webRTCAdaptor.current = new WebRTCAdaptor({
        websocket_url:
          "wss://" + config.server.live.domain + "WebRTCAppEE/websocket",
        mediaConstraints: {
          video: false,
          audio: false,
        },
        peerconnection_config: {
          iceServers: [{ urls: config.server.live.ice }],
        },
        sdp_constraints: {
          OfferToReceiveAudio: false,
          OfferToReceiveVideo: false,
        },
        remoteVideoId: "remoteVideo",
        // bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
        // dataChannelObserver: true, // enable or disable data channel
        callback: (info, obj) => {
          switch (info) {
            case "pong":
              break;
            case "bitrateMeasurement":
              break;
            case "initialized":
              console.log(info);
              setWebsocketConnected(true);
              break;
            case "play_started":
              console.log(info);
              setPlaying(true);
              break;
            case "data_channel_opened":
              console.log(info);
              setDataChannel(true);
              break;
            case "data_received":
              console.log(info);
              console.log(obj);
              try {
                let msg = JSON.parse(obj.data);
                if (msg.chat) popMessage(msg.chat);
                if (Number.isInteger(msg.count)) setActiveCount(msg.count);
              } catch (e) {}
              break;
            case "data_channel_closed":
              console.log(info);
              setDataChannel(false);
              break;
            case "play_finished":
              console.log(info);
              webRTCAdaptor.current.stop(playingStreamId.current);
              resetPlayer();
              break;
            default:
              console.log(info);
              // console.log(obj);
              break;
          }
        },
        callbackError: function (error, message) {
          console.log(error, message);
          resetPlayer();
          // webRTCAdaptor.current.stop(playingStreamId.current);
        },
      });
    }
  }, []);

  ////////////// FUNCTIONS //////////////////
  const initPlay = async () => {
    setStreamName(null);
    setError(null);
    let _stream = streamRef.current.value.trim();
    if (_stream) {
      // retrieve token and stream id
      let result = await axiosCall("live/totp", {
        streamId: _stream,
        type: "play",
      });
      if (result.success) {
        console.log(result.data);
        if (result.status === 200) {
          handlePlay(result.data);
        } else {
          setError(result.data.message);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        initPlay();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  const handlePlay = (obj) => {
    if (obj.streamName) setStreamName(obj.streamName);
    if (
      obj.streamId &&
      obj.subscriberId &&
      obj.subscriberCode &&
      obj.username &&
      obj.avatar
    ) {
      playingStreamId.current = obj.streamId;
      username.current = obj.username;
      avatar.current = obj.avatar;
      webRTCAdaptor.current.play(
        obj.streamId,
        "",
        "",
        [],
        obj.subscriberId,
        obj.subscriberCode
      );
    }
  };

  const handleStopPlaying = () => {
    webRTCAdaptor.current.stop(playingStreamId.current);
  };

  const resetPlayer = () => {
    setPlaying(false);
    setStreamName(null);
    streamRef.current.value = null;
    playingStreamId.current = null;
    username.current = null;
    avatar.current = null;
  };

  const sendMessage = () => {
    let msg = msgRef.current.value.trim();
    if (msg) postMessage(msg);
  };

  const postMessage = (message) => {
    if (playingStreamId.current && username.current && avatar.current) {
      let messageObj = {
        chat: {
          message,
          username: username.current,
          avatar: avatar.current,
        },
      };
      webRTCAdaptor.current.sendData(
        playingStreamId.current,
        JSON.stringify(messageObj)
      );
      popMessage({ message, self: true });
    }
  };

  const popMessage = (message) => {
    let HTML;
    if (message.count) {
      setActiveCount(message.count);
    } else if (message.self) {
      HTML = `<div style='align-self:flex-end; text-align:right'><i>${message.message}</i> &nbsp; - &nbsp; <b>me</b></div>`;
    } else {
      HTML = `<div style='display:flex; gap: 12px'><div><div class='avatar med' style='background-image: url(${
        config.content.server + message.avatar
      })'></div><div><b>${message.username}</b></div></div><div><i>${
        message.message
      }</i></div></div>`;
    }
    msgBox.current.innerHTML = HTML + msgBox.current.innerHTML;
    msgRef.current.value = "";
  };

  ////////////// RENDER GUI //////////////////

  return (
    <main className="main-page">
      <div className="page-section">
        <h2>Live Stream Player</h2>
      </div>
      {streamName && playing && (
        <div className="page-section sub">
          PLAYING STREAM: <b>{streamName}</b>
        </div>
      )}
      {playing && activeCount > 0 && (
        <div className="page-section sub">
          VIEWERS: <b>{activeCount}</b>
        </div>
      )}
      <div className="page-section sub" style={{ maxWidth: "480px" }}>
        <video
          style={{ display: `${playing ? "" : "none"}` }}
          id="remoteVideo"
          controls={true}
          autoPlay={true}
          muted={false}
          playsInline={true}
          width="480px"
          height="360px"
        ></video>
      </div>
      <div className="page-section sub">
        {error && <div className="errtext">{error}</div>}
        <input
          type="text"
          ref={streamRef}
          placeholder="enter stream id"
          readOnly={playing}
        ></input>
        <div style={{ marginTop: "0.25em" }}>
          {playing ? (
            <button className="button" onClick={handleStopPlaying}>
              LEAVE STREAM
            </button>
          ) : (
            <button
              className="button"
              disabled={!websocketConnected}
              onClick={initPlay}
            >
              PLAY STREAM
            </button>
          )}
        </div>
        {dataChannel && (
          <>
            <div style={{ marginTop: "2em" }}>LIVE CHAT</div>

            <div style={{ marginTop: "0.25em", display: "flex", gap: "0.5em" }}>
              <input
                type="text"
                ref={msgRef}
                placeholder="enter a message"
              ></input>
              <button className="button" onClick={() => sendMessage()}>
                Send
              </button>
            </div>

            <div
              ref={msgBox}
              style={{
                marginTop: "0.5em",
                display: "flex",
                maxWidth: "480px",
                height: "200px",
                border: "1px solid black",
                padding: "0.5em",
                flexDirection: "column-reverse",
                gap: "0.5em",
                overflow: "scroll",
              }}
            ></div>
          </>
        )}
      </div>
    </main>
  );
};

export default LivePlay;
