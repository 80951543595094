import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosCall from "../../../lib/axios";
import validator from "../../../lib/validation";
import { BlockEditor } from "../../widgets";
import Modal from "../../modal/Modal";

const BlockAbout = ({
  readOnly,
  language,
  block,
  setBlock,
  setUpdateError,
  gotPathways,
  blockPathways,
  getPathways,
  communityPathways,
  main_focus,
  update_error,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  const navigate = useNavigate();
  const [confirmWithdraw, setConfirmWithdraw] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [pathwayModal, setPathwayModal] = useState(false);
  const [pids, setPids] = useState([]);

  useEffect(() => {
    if (pathwayModal) {
      //set currently selected
      let _pids = [];
      const pathway_selector = document.getElementsByName("pathway_selector");
      for (var i = 0; i < pathway_selector.length; i++) {
        for (const pwy of blockPathways) {
          if (pwy.pid === pathway_selector[i].value) {
            pathway_selector[i].checked = true;
            _pids.push(pwy.pid);
          }
        }
      }
      setPids(_pids);
    }
  }, [pathwayModal]);

  //////////// FUNCTIONS ///////////
  const withdrawBlock = async () => {
    let result = await axiosCall("block/withdraw", { bid: block._id });
    if (result.success) {
      navigate("/bites");
    } else if (result.refresh) {
      //token has been refreshed, try again
      withdrawBlock();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  const modifyPathways = async () => {
    // get selections
    let additions = new Array();
    let subtractions = new Array();

    const pathway_selector = document.getElementsByName("pathway_selector");
    for (var i = 0; i < pathway_selector.length; i++) {
      if (pathway_selector[i].checked) {
        if (!pids.includes(pathway_selector[i].value))
          additions.push(pathway_selector[i].value);
      } else {
        if (pids.includes(pathway_selector[i].value))
          subtractions.push(pathway_selector[i].value);
      }
    }

    if (additions.length + subtractions.length > 0) {
      document.getElementById("loader").style.display = "inline";
      const data = {
        bid: block._id,
        thumb: block.thumb,
        additions,
        subtractions,
      };
      let result = await axiosCall("block/pathways", data, false);
      if (result.success) {
        getPathways();
        setPathwayModal(false);
      } else if (result.refresh) {
        //token has been refreshed, try again
        modifyPathways();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } else {
      //no changes
      setPathwayModal(false);
    }
  };

  const cancelPathways = () => {
    setPathwayModal(false);
  };

  //////////// RENDER GUI ///////////
  const pathwaySelector = communityPathways.map((pwy, index) => (
    <div
      key={index}
      style={{
        display: "flex",
        gap: "0.5em",
        marginTop: "0.5em",
        alignItems: "flex-start",
      }}
    >
      <input
        name="pathway_selector"
        id={`pwy_${index}`}
        value={pwy._id}
        type="checkbox"
        defaultChecked={false}
      />
      <label className="hover" htmlFor={`pwy_${index}`}>
        {pwy.pathway}
      </label>
    </div>
  ));

  const Pathways = blockPathways.map((pwy, index) => (
    <div key={index} style={{ marginTop: "0.5em" }}>
      {pwy.pathway}
    </div>
  ));

  return (
    <div
      role="tabpanel"
      style={{ paddingBottom: "2em" }}
      aria-label={language.labels.app.about}
      tabIndex={0}
    >
      <BlockEditor
        readOnly={readOnly}
        language={language}
        block={block}
        setBlock={setBlock}
        setUpdateError={setUpdateError}
        main_focus={main_focus}
        signout={signout}
        ref={update_error}
      ></BlockEditor>

      {/* FOLLOWERS */}
      {block.count_bookmarks > 0 && (
        <div
          style={{ display: "flex", marginTop: "2em", alignItems: "center" }}
        >
          <div
            role="img"
            className="glyphs"
            style={{ flexBasis: "1.5em", fontSize: "1.2em" }}
            aria-label={language.labels.aria.bookmarks}
          >
            q
          </div>
          {block.count_bookmarks < 2 ? (
            <div>{language.labels.app.bookmarked_one}</div>
          ) : (
            <div>
              {language.labels.app.bookmarked_many.replace(
                /{n}/g,
                validator.bigNumber(block.count_bookmarks || 0)
              )}
            </div>
          )}
        </div>
      )}

      {/* PATHWAYS */}
      {gotPathways && (
        <>
          <h2
            className="heading2"
            style={{ fontWeight: "600", marginTop: "2em" }}
          >
            {language.labels.navigation.pathways}
          </h2>
          {blockPathways.length > 0 ? (
            <>{Pathways}</>
          ) : (
            <div style={{ marginTop: "0.5em" }}>
              {language.labels.bites.pathways.none}
            </div>
          )}
          {!readOnly && (
            <button
              className="link"
              style={{ marginTop: "1em" }}
              onClick={() => setPathwayModal(true)}
            >
              {language.labels.bites.pathways.change.toLowerCase()}
            </button>
          )}
        </>
      )}

      {/* WITHDRAW BLOCK */}
      {!readOnly && (
        <div style={{ display: "flex", marginTop: "1.5em" }}>
          <button
            className="button"
            style={{ fontSize: "0.85em", marginTop: "1.5em" }}
            onClick={() => setWithdrawModal(true)}
          >
            {language.labels.bites.withdraw}
          </button>
        </div>
      )}

      {/* MODALS */}
      <Modal
        className="modal-dynamic"
        title={language.labels.bites.withdraw}
        show={withdrawModal}
      >
        <div
          style={{ fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.bites.withdraw_warning,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: ".9rem",
            marginTop: "1rem",
          }}
        >
          <input
            id="consequence_understood"
            type={"checkbox"}
            onChange={(e) => setConfirmWithdraw(e.target.checked)}
            defaultChecked={confirmWithdraw}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
          <label className="hover" htmlFor="consequence_understood">
            {language.labels.app.consequences_understood}
          </label>
        </div>
        {confirmWithdraw && (
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <button className="button" onClick={() => withdrawBlock()}>
              {language.labels.bites.withdraw_now}
            </button>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "2em" }}>
          <button
            className="button-off"
            onClick={() => setWithdrawModal(false)}
          >
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>

      <Modal
        children={
          communityPathways.length > 0 ? (
            <div>
              <div style={{ paddingBottom: "0.5em", fontSize: "0.9em" }}>
                {language.labels.bites.pathways.modify}
              </div>
              {pathwaySelector}
            </div>
          ) : (
            <div>{language.labels.bites.pathways.none}</div>
          )
        }
        className="modal-dynamic"
        title={language.labels.bites.pathways.change}
        show={pathwayModal}
        onClose={cancelPathways}
        onOk={communityPathways.length > 0 ? modifyPathways : cancelPathways}
        language={language}
      ></Modal>
    </div>
  );
};

export default BlockAbout;
